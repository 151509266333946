/**
 * Obsługa topnav, menu, schowek, toggle klas przy scrollu etc
 * 
 */

(function($) {
$(document).ready(function(){
    
//    var bsBreakpointXs = getComputedStyle(document.body).getPropertyValue('--breakpoint-xs');
//    var breakpointY = 160;
    
//    $(window).on('load scroll resize', _.debounce(function(){
//      if($(document).scrollTop() > breakpointY || !$('body').hasClass('home')){
//        $('#topnav .navbar-brand').removeClass('d-none').addClass('d-block');
//      }else{
//        $('#topnav .navbar-brand').removeClass('d-block').addClass('d-none');
//      }
//    }, 250));
    
    /**
     * Snippet. Jezeli z elementu triggerujacego popover przejedziemy do jego popovera to on nie znika dopoki nie opuscimy tego elementu
     */
//    $('[data-toggle="popover"]').popover({
//        trigger: 'manual',
//        html: true,
//        animation: false,
//        //content: $('#contact-container').html(),
//        html: true,
//        placement: 'bottom'
//    })
//    .on('mouseenter', function () {
//        var _this = this;
//        $(this).popover('show');
//        //$('#contact').addClass('hover');
//        $('.popover').on('mouseleave', function () {
//            $(_this).popover('hide');
//            $('#contact').removeClass('hover');
//        });
//    }).on('mouseleave', function () {
//        var _this = this;
//        setTimeout(function () {
//            if (!$('.popover:hover').length) {
//                $(_this).popover('hide');
//                //$('#contact').removeClass('hover');
//            }
//        }, 300);
//    });
    
});
})( jQuery );