//init triggers
document.addEventListener("DOMContentLoaded", function() {
    // Code to be executed when the DOM is ready
    var form_u = document.querySelector('#kalkulator_u');
    var buttonsubmit = document.querySelector('#doBtn');
    var buttonclear = document.querySelector('#clearBtn');

    var selectwarstwa = document.querySelector('#typeKal2w1');
    var selectrodzaj = document.querySelector('#typeKal2w2');
    var selectelew = document.querySelector('#typeKal2w3');

    form_u.addEventListener('submit',function(){
        return doKal2();
    });
    buttonsubmit.addEventListener('click',function(){
        return setDoKal2();
    });
    buttonclear.addEventListener('click',function(){
        return clearKal2();
    });
    selectwarstwa.addEventListener('change',function(){
        return setTypeKal2('w1');
    });
    selectrodzaj.addEventListener('change',function(){
        return setTypeKal2('w2');
    });
    selectelew.addEventListener('change',function(){
        return setTypeKal2('w3');
    });


    //console.log(form_u);
}); 


var kal1Type = 'P';
var kal1M2 = 0;
var kal1Mm = 0;

function setTypeKal1() {
    kal1Type = hval(document.querySelector('#typeKal1'));
    document.querySelector('.mm-type-kal1').style.display = 'none';;
    document.querySelector('#mm-' + kal1Type).style.display = '';
};
var setKal1 = {};
setKal1.P = {};
setKal1.P.m2 = 0.50;
setKal1.P.option = {};
setKal1.P.option.mm10 = 50;
setKal1.P.option.mm20 = 30;
setKal1.P.option.mm30 = 20;
setKal1.P.option.mm40 = 15;
setKal1.P.option.mm50 = 12;
setKal1.P.option.mm60 = 10;
setKal1.P.option.mm70 = 8;
setKal1.P.option.mm80 = 7;
setKal1.P.option.mm90 = 6;
setKal1.P.option.mm100 = 6;
setKal1.P.option.mm110 = 5;
setKal1.P.option.mm120 = 5;
setKal1.P.option.mm130 = 4;
setKal1.P.option.mm140 = 4;
setKal1.P.option.mm150 = 4;
setKal1.P.option.mm160 = 3;
setKal1.P.option.mm170 = 3;
setKal1.P.option.mm180 = 3;
setKal1.P.option.mm190 = 3;
setKal1.P.option.mm200 = 3;
setKal1.P.option.mm210 = 2;
setKal1.P.option.mm220 = 2;
setKal1.P.option.mm230 = 2;
setKal1.P.option.mm240 = 2;
setKal1.P.option.mm250 = 2;
setKal1.P.option.mm260 = 2;
setKal1.P.option.mm270 = 2;
setKal1.P.option.mm280 = 2;
setKal1.P.option.mm290 = 2;
setKal1.P.option.mm300 = 2;
setKal1.F = {};
setKal1.F.m2 = 0.4777;
setKal1.F.option = {};
setKal1.F.option.mm50 = 12;
setKal1.F.option.mm60 = 10;
setKal1.F.option.mm70 = 8;
setKal1.F.option.mm80 = 7;
setKal1.F.option.mm90 = 6;
setKal1.F.option.mm100 = 6;
setKal1.F.option.mm110 = 5;
setKal1.F.option.mm120 = 5;
setKal1.F.option.mm130 = 4;
setKal1.F.option.mm140 = 4;
setKal1.F.option.mm150 = 4;
setKal1.F.option.mm160 = 3;
setKal1.F.option.mm170 = 3;
setKal1.F.option.mm180 = 3;
setKal1.F.option.mm190 = 3;
setKal1.F.option.mm200 = 3;
setKal1.F.option.mm210 = 2;
setKal1.F.option.mm220 = 2;
setKal1.F.option.mm230 = 2;
setKal1.F.option.mm240 = 2;
setKal1.F.option.mm250 = 2;
setKal1.F.option.mm260 = 2;
setKal1.F.option.mm270 = 2;
setKal1.F.option.mm280 = 2;
setKal1.F.option.mm290 = 2;
setKal1.F.option.mm300 = 2;
setKal1.A = {};
setKal1.A.m2 = 0.741;
setKal1.A.option = {};
setKal1.A.option.mm50 = 12;
setKal1.A.option.mm60 = 10;
setKal1.A.option.mm70 = 8;
setKal1.A.option.mm80 = 7;
setKal1.A.option.mm90 = 6;
setKal1.A.option.mm100 = 6;
setKal1.A.option.mm110 = 5;
setKal1.A.option.mm120 = 5;
setKal1.A.option.mm130 = 4;
setKal1.A.option.mm140 = 4;
setKal1.A.option.mm150 = 4;
setKal1.A.option.mm160 = 3;
setKal1.A.option.mm170 = 3;
setKal1.A.option.mm180 = 3;
setKal1.A.option.mm190 = 3;
setKal1.A.option.mm200 = 3;
setKal1.T = {};
setKal1.T.m2 = 0.768;
setKal1.T.option = {};
setKal1.T.option.mm20 = 10;

function setDoKal1() {
    kal1M2 = hval(document.querySelector('#m2')).replace(',', '.');
    kal1Mm = hval(document.querySelector('#mm-' + kal1Type));
    var powPacz = setKal1[kal1Type].m2 * setKal1[kal1Type].option['mm' + kal1Mm];
    var ilPacz = Math.ceil(kal1M2 / powPacz);
    document.querySelector('#kal1-result').style.display = '';
    document.querySelector('#kal1-result span').innerHTML = ilPacz;
};

function clearKal1() {
    document.querySelector('#m2').attr('value', '')
    document.querySelector('#kal1-result').style.display = 'none';
};

var kal2Lambda = {};

function setTypeKal2(w) {
    if (w == 'w1') {
        if (hval(document.querySelector('#typeKal2w1')) == 'im') {
            let tmp = document.querySelectorAll('.imw1');
            tmp.forEach((item) => item.style.display = '');
            
            tmp = document.querySelectorAll('.imw1x');
            tmp.forEach((item) => item.style.display = 'none');

        } else {
            let tmp = document.querySelectorAll('.imw1');
            tmp.forEach((item) => item.style.display = 'none');

            tmp = document.querySelectorAll('.imw1x');
            tmp.forEach((item) => item.style.display = '');

            var selW1 = hval(document.querySelector('#typeKal2w1'));
            if (selW1 == 'm0') {
                tmp = document.querySelectorAll('.imw1x');
                tmp.forEach((item) => item.style.display = 'none');
            } else {
                tmp = document.querySelectorAll('.imw1x');
                tmp.forEach((item) => item.style.display = '');

                var lambda = setKal2.w1[selW1].lambda;
                document.querySelector('#w1La').innerHTML = lambda;

                var d = setKal2.w1[selW1].d
                document.querySelector('#w1Cm').innerHTML = d;
            }
        }
    }
    if (w == 'w2') {
        if (hval(document.querySelector('#typeKal2w2')) == 'im') {
            let tmp = document.querySelectorAll('.imw1');
            tmp.forEach((item) => item.style.display = '');
            
            tmp = document.querySelectorAll('.imw1x');
            tmp.forEach((item) => item.style.display = 'none');
        } else {
            let tmp = document.querySelectorAll('.imw2x');
            tmp.forEach((item) => item.style.display = '');

            var selW2 = hval(document.querySelector('#typeKal2w2'));
            if (selW2 == 'm0') {
                tmp = document.querySelectorAll('.imw2x');
                tmp.forEach((item) => item.style.display = 'none');
            } else {
                tmp = document.querySelectorAll('.imw2x');
                tmp.forEach((item) => item.style.display = '');

                var lambda = setKal2.w2[selW2].lambda;
                document.querySelector('#w2La').innerHTML = lambda.toFixed(3);
            }
        }
    }
    if (w == 'w3') {
        if (hval(document.querySelector('#typeKal2w3')) == 'im') {
            let tmp = document.querySelectorAll('.imw3');
            tmp.forEach((item) => item.style.display = '');
            
            tmp = document.querySelectorAll('.imw3x');
            tmp.forEach((item) => item.style.display = 'none');
        } else {
            let tmp = document.querySelectorAll('.imw3');
            tmp.forEach((item) => item.style.display = 'none');

            tmp = document.querySelectorAll('.imw3x');
            tmp.forEach((item) => item.style.display = '');

            var selW3 = hval(document.querySelector('#typeKal2w3'));
            if (selW3 == 'm0') {
                tmp = document.querySelectorAll('.imw3x');
                tmp.forEach((item) => item.style.display = 'none');
            } else {
                tmp = document.querySelectorAll('.imw3x');
                tmp.forEach((item) => item.style.display = '');
                var lambda = setKal2.w3[selW3].lambda;
                document.querySelector('#w3La').innerHTML = lambda;
                var d = setKal2.w3[selW3].d
                document.querySelector('#w3Cm').innerHTML = d;
            }
        }
    }
};
var setKal2 = {};
setKal2.w1 = {};
setKal2.w1.m0 = {};
setKal2.w1.m0.lambda = 0;
setKal2.w1.m0.r = 0;
setKal2.w1.m0.d = 0;
setKal2.w1.m1 = {};
setKal2.w1.m1.lambda = 0.46;
setKal2.w1.m1.r = 0.522;
setKal2.w1.m1.d = 24;
setKal2.w1.m3 = {};
setKal2.w1.m3.lambda = 0.313;
setKal2.w1.m3.r = 0.962;
setKal2.w1.m3.d = 25;
setKal2.w1.m4 = {};
setKal2.w1.m4.lambda = 0.233;
setKal2.w1.m4.r = 1.579;
setKal2.w1.m4.d = 30;
setKal2.w1.m5 = {};
setKal2.w1.m5.lambda = 0.143;
setKal2.w1.m5.r = 2.375;
setKal2.w1.m5.d = 38;
setKal2.w1.m6 = {};
setKal2.w1.m6.lambda = 0.31;
setKal2.w1.m6.r = 0.806;
setKal2.w1.m6.d = 25;
setKal2.w1.m7 = {};
setKal2.w1.m7.lambda = 0.19;
setKal2.w1.m7.r = 1.579;
setKal2.w1.m7.d = 30;
setKal2.w1.m8 = {};
setKal2.w1.m8.lambda = 0.16;
setKal2.w1.m8.r = 2.375;
setKal2.w1.m8.d = 38;
setKal2.w1.m9 = {};
setKal2.w1.m9.lambda = 0.11;
setKal2.w1.m9.r = 2.286;
setKal2.w1.m9.d = 24;
setKal2.w1.m10 = {};
setKal2.w1.m10.lambda = 0.16;
setKal2.w1.m10.r = 1.500;
setKal2.w1.m10.d = 24;
setKal2.w1.m11 = {};
setKal2.w1.m11.lambda = 0.11;
setKal2.w1.m11.r = 2.182;
setKal2.w1.m11.d = 24;
setKal2.w1.m12 = {};
setKal2.w1.m12.lambda = 0.11;
setKal2.w1.m12.r = 2.727;
setKal2.w1.m12.d = 30;
setKal2.w1.m13 = {};
setKal2.w1.m13.lambda = 0.16;
setKal2.w1.m13.r = 1.548;
setKal2.w1.m13.d = 24;
setKal2.w1.m14 = {};
setKal2.w1.m14.lambda = 0.16;
setKal2.w1.m14.r = 1.935;
setKal2.w1.m14.d = 30;
setKal2.w1.m15 = {};
setKal2.w1.m15.lambda = 0.11;
setKal2.w1.m15.r = 2.182;
setKal2.w1.m15.d = 24;
setKal2.w1.m16 = {};
setKal2.w1.m16.lambda = 0.17;
setKal2.w1.m16.r = 1.412;
setKal2.w1.m16.d = 24;
setKal2.w1.m17 = {};
setKal2.w1.m17.lambda = 0.17;
setKal2.w1.m17.r = 1.765;
setKal2.w1.m17.d = 30;
setKal2.w1.m18 = {};
setKal2.w1.m18.lambda = 0.40;
setKal2.w1.m18.r = 0.625;
setKal2.w1.m18.d = 25;
setKal2.w1.m19 = {};
setKal2.w1.m19.lambda = 0.40;
setKal2.w1.m19.r = 0.725;
setKal2.w1.m19.d = 29;
setKal2.w1.m20 = {};
setKal2.w1.m20.lambda = 0.77;
setKal2.w1.m20.r = 0.325;
setKal2.w1.m20.d = 25;
setKal2.w1.m24 = {};
setKal2.w1.m24.lambda = 1.70;
setKal2.w1.m24.r = 0.147;
setKal2.w1.m24.d = 25;
setKal2.w1.m25 = {};
setKal2.w1.m25.lambda = 1.70;
setKal2.w1.m25.r = 0.088;
setKal2.w1.m25.d = 15;
setKal2.w2 = {};
setKal2.w2.m0 = {};
setKal2.w2.m0.lambda = 0;
setKal2.w2.m1 = {};
setKal2.w2.m1.lambda = 0.0440;
setKal2.w2.m2 = {};
setKal2.w2.m2.lambda = 0.0420;
setKal2.w2.m3 = {};
setKal2.w2.m3.lambda = 0.0400;
setKal2.w2.m4 = {};
setKal2.w2.m4.lambda = 0.0380;
setKal2.w2.m5 = {};
setKal2.w2.m5.lambda = 0.0400;
setKal2.w2.m6 = {};
setKal2.w2.m6.lambda = 0.0380;
setKal2.w2.m7 = {};
setKal2.w2.m7.lambda = 0.0370;
setKal2.w2.m8 = {};
setKal2.w2.m8.lambda = 0.0360;
setKal2.w2.m9 = {};
setKal2.w2.m9.lambda = 0.0350;
setKal2.w2.m10 = {};
setKal2.w2.m10.lambda = 0.0340;
setKal2.w2.m11 = {};
setKal2.w2.m11.lambda = 0.0330;
setKal2.w2.m12 = {};
setKal2.w2.m12.lambda = 0.0310;
setKal2.w2.m13 = {};
setKal2.w2.m13.lambda = 0.0300;
setKal2.w3 = {};
setKal2.w3.m0 = {};
setKal2.w3.m0.lambda = 0;
setKal2.w3.m0.r = 0;
setKal2.w3.m0.d = 0;
setKal2.w3.m2 = {};
setKal2.w3.m2.lambda = 0.46;
setKal2.w3.m2.r = 0.261;
setKal2.w3.m2.d = 12;
setKal2.w3.m3 = {};
setKal2.w3.m3.lambda = 0.16;
setKal2.w3.m3.r = 0.719;
setKal2.w3.m3.d = 11, 5;
setKal2.w3.m4 = {};
setKal2.w3.m4.lambda = 0.16;
setKal2.w3.m4.r = 0.774;
setKal2.w3.m4.d = 12;
setKal2.w3.m5 = {};
setKal2.w3.m5.lambda = 0.17;
setKal2.w3.m5.r = 0.706;
setKal2.w3.m5.d = 12;
setKal2.w3.m6 = {};
setKal2.w3.m6.lambda = 0.307;
setKal2.w3.m6.r = 0.548;
setKal2.w3.m6.d = 11.5;
setKal2.w3.m7 = {};
setKal2.w3.m7.lambda = 0.30;
setKal2.w3.m7.r = 0.383;
setKal2.w3.m7.d = 11.5;
setKal2.w3.m8 = {};
setKal2.w3.m8.lambda = 0.77;
setKal2.w3.m8.r = 0.156;
setKal2.w3.m8.d = 12;
setKal2.w3.m9 = {};
setKal2.w3.m9.lambda = 1.05;
setKal2.w3.m9.r = 0.114;
setKal2.w3.m9.d = 12;
setKal2.w3.m11 = {};
setKal2.w3.m11.lambda = 0.45;
setKal2.w3.m11.r = 0.267;
setKal2.w3.m11.d = 12;

function onlydec(event, f) {
    if (event.srcElement) {
        kc = event.keyCode;
    } else {
        kc = event.which;
    }
    if ((kc < 47 || kc > 57) && kc != 8 && kc != 0) return false;
    return true;
}

function setDoKal2() {
    var selW1 = hval(document.querySelector('#typeKal2w1'));
    if (selW1 == 'm0') {
        document.querySelector('#kal2-result').style.display = 'none';;
        document.querySelector('#komunikat').style.display = '';;
        return
    }
    var RW1 = 0;
    if (selW1 != 'im') {
        RW1 = setKal2.w1[selW1].r;
    } else {
        var lambda = hval(document.querySelector('#w1l')).replace(',', '.');
        var d = hval(document.querySelector('#w1mm')).replace(',', '.');
        RW1 = (d / 100) / lambda
    }
    var selW2 = hval(document.querySelector('#typeKal2w2'));
    if (selW2 == 'm0') {
        document.querySelector('#kal2-result').style.display = 'none';;
        document.querySelector('#komunikat').style.display = '';;
        return
    }
    var lambdaW2 = setKal2.w2[selW2].lambda;
    var dW2 = hval(document.querySelector('#w2mm')).replace(',', '.');
    if (dW2 == '') {
        document.querySelector('#kal2-result').style.display = 'none';;
        document.querySelector('#komunikat').style.display = '';;
        return
    }
    RW2 = (dW2 / 100) / lambdaW2
    var selW3 = hval(document.querySelector('#typeKal2w3'));
    var RW3 = 0;
    if (selW3 == 'm0') {
        RW3 = 0
    } else if (selW3 != 'im') {
        RW3 = setKal2.w3[selW3].r;
    } else {
        var lambda3 = hval(document.querySelector('#w3l')).replace(',', '.');
        var d3 = hval(document.querySelector('#w3mm')).replace(',', '.');
        RW3 = (d3 / 100) / lambda3
    }
    var U = 1 / (0.17 + RW1 + RW2 + RW3);

    document.querySelector('#kal2-result').style.display = '';
    document.querySelector('#komunikat').style.display = 'none';
    document.querySelector('#kal2-result span').innerHTML = U.toFixed(2);
};

function clearKal2() {
    document.querySelector('#m2').attr('value', '')
    document.querySelector('#kal2-result').style.display = 'none';
    document.querySelector('.imw1x').style.display = 'none';
    document.querySelector('.imw2x').style.display = 'none';
    document.querySelector('.imw3x').style.display = 'none';
};

/**
 * jquery to vanilla js
 */

function hval(el) {
    if (el.options && el.multiple) {
      return el.options
        .filter((option) => option.selected)
        .map((option) => option.value);
    } else {
      return el.value;
    }
  }
  