/**
 * Zmiana hasha
 */

$(function(){

  $(window).bind( 'hashchange', function(e) {

		var hrefsplit = window.location.href.split('#');

		if (hrefsplit.length > 1) {
			var param = hrefsplit[1].split(':');
		} else {
			var param = "";
		}
		
		//alert('podstrona '+param[0]);
		//alert('lp '+param[1]);
		//alert('strona paginacji '+param[2]);
		


		// param[0] podstrona
		// param[1] lp [paginacja]
		// param[2] strona paginacji

		switch(param[0]) {

		case 'tab-historia':
			$('#content').load(domena+'index/panelklienta/',function(){
				$('#tab-historia').load(domena+'zamowienia/zamowienia/',function() {
					$(".klient-tab").hide(0); //Hide all content
					$('a[rel=tab-dane]').parent().removeClass('active');
					$('a[rel=tab-historia]').parent().addClass('active');
					$("#tab-historia").show(0); //Show tab content
				});
			});
		  break;

		case 'tab-dane':
			$('#content').load(domena+'index/panelklienta/',function(){
				$('#tab-dane').load(domena+'logowanie/zmianadanych/',function(){
					$(".klient-tab").hide(0); //Hide all content
					$('a[rel=tab-historia]').parent().removeClass('active');
					$('a[rel=tab-dane]').parent().addClass('active');
					$("#tab-dane").show(0); //Show tab content
				});
			});
		  break;

		case 'koszyk':
			$('#content').load(domena+'koszyk/produkty/');
		  break;

		case 'przechowalnia':
			$('#content').load(domena+'przechowalnia/produkty/');
		  break;

		case 'rejestracja':
			$('#content').load(domena+'logowanie/rejestracja/');
		  break;

		case 'kasa':
			$('#content').load(domena+'kasa/kasa/');
		  break;

		case 'zamowienie':
			$('#content').load(domena+'kasa/zamowienie/');
		  break;


		case 'daneosobowe':
			$('#content').load(domena+'kasa/daneosobowe/');
		  break;

		case 'panelklienta':
			$('#content').load(domena+'index/panelklienta/', function() {
				$('#tab-historia').load(domena+'zamowienia/zamowienia/',function() {
					$(".klient-tab").hide(0); //Hide all content
					$('a[rel=tab-dane]').parent().removeClass('active');
					$('a[rel=tab-historia]').parent().addClass('active');
					$("#tab-historia").show(0); //Show tab content
				});
			});
		  break;

		case 'obsluzplatnosci':
			$('#content').load(domena+'obsluzplatnosci/');
		  break;

		case 'listaproduktow':
			var link = hrefsplit[0].split('.htm');
			$('#content').load(link[0]+'_'+param[1]+'_'+param[2]+'.html');
		  break;

		case 'wyszukiwanie':
			if (param[3] && param[4]) { var lp = '/'+param[3]+'/'+param[4];} else { var lp = '';}
			$('#content').load(domena+'wyszukiwarka/index/search-field/'+param[2]+lp);
		  break;

		}

  });

  $(window).trigger( 'hashchange' );

});
