/**
 * Skrypt doczepiany do strony głównej i w sumie wszędzie w systemie
 */
/**
 * Koszyk
 * @uses root_path - globalny parametr glownej sciezki
 * @uses jezyk - globalny parametr jezyka
 * @param {int} id
 * @param {url} path
 */
function koszyk(id, path) {
  var html = $.ajax({
    url: path+"koszyk/czywarianty/id/"+id+"/",
    async: false
  }).responseText;

  var url = root_path + ( jezyk !== 'pl' ? jezyk + '/' : '' ) + ( html == 'true' ? "koszyk/dokoszyka/id/" : "koszyk/iloscdokoszyka/id/" ) +id+"/";
  $('#koszyk-wrapper .modal-body').load(url, function(){
    $('#koszyk-wrapper').modal('show');
  });
}

(function($) {

  $('.modal[data-animation-in][data-animation-out]').on({
      'show.bs.modal': function(){ $(this).find('.modal-dialog').velocity($(this).attr('data-animation-in')); },
      'hide.bs.modal': function(){ $(this).find('.modal-dialog').velocity($(this).attr('data-animation-out')); }
  });

  /** Bootstrap breakpoints */
  var bsBreakpoint = {
    xs: getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xs'),
    sm: getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-sm'),
    md: getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-md'),
    lg: getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-lg'),
    xl: getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xl'),
    xxl: getComputedStyle(document.documentElement).getPropertyValue('--breakpoint-xxl'),
  };
  
  $('#koszyk-warpper').on({
    'show.bs.modal': function(){ },
    'hide.bs.modal': function(){  $(this).html(''); },
  });
  
   /**
    * Tworzy ciacho
    * @todo - ustalic gdzie to
    * @param {type} name
    * @param {type} value
    * @param {type} days
    * @returns {undefined}
    */
  function createCookie(name,value,days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      var expires = "; expires="+date.toGMTString();
    }else{
      var expires = "";
    }
    document.cookie = name+"="+value+expires+"; path=/";
  }

  
  $(document).ready(function(){
    /** Kategorie - rozwijane menu */
    var $categoriesWrapper = $('.menu-3');
    var $categoriesSubmenuWrapper = $categoriesWrapper.find('li ul');
    
    $categoriesWrapper.find('li a ~ ul').addClass('collapse');
    
    
    $('<button><i class="fa fa-angle-up"></i></button>')
      .on('click', function(){ $(this).toggleClass('active').next().collapse('toggle'); $(this).parent().toggleClass('active'); })
      .insertBefore( $categoriesSubmenuWrapper );
      
//    $categoriesWrapper.find('li a#kategoria-aktywna').parent().addClass('active');
//    $categoriesWrapper.find('li a#kategoria-aktywna ~ ul').addClass('show');
//    $categoriesWrapper.find('li a#kategoria-aktywna ~ button').addClass('active');
    
    $categoriesWrapper.find('a#kategoria-aktywna').parents('li').addClass('active');
    $categoriesWrapper.find('a#kategoria-aktywna').parents('li').find('>ul').addClass('show');
    $categoriesWrapper.find('a#kategoria-aktywna').parents('li').find('>button').addClass('active');
      
    /** Karuzela */
    var owlSettings = {
      autoplay: false,
      loop: true,
      autoplayHoverPause: false,
      nav:  true,
      dots: false,
      items: 1,
      stagePadding: 0,
      margin: 0,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    };
    owlSettings.responsive = {};
    owlSettings.responsive[parseInt(bsBreakpoint.md)] = { items: 1 };
    owlSettings.responsive[parseInt(bsBreakpoint.lg)] = { items: 2 };
    owlSettings.responsive[parseInt(bsBreakpoint.xl)] = { items: 3 };
    owlSettings.responsive[parseInt(bsBreakpoint.xxl)] = { items: 4, nav: true };
    var $owl = $('.owl-carousel-styropiany, .owl-carousel-slider').owlCarousel(owlSettings);
    
    var owlSettings2 = {
      autoplay: false,
      loop: true,
      autoplayHoverPause: false,
      nav:  true,
      dots: false,
      items: 1,
      stagePadding: 0,
      margin: 0,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
    };
    owlSettings2.autoplay = true;
    owlSettings2.autoplayHoverPause = true;
    owlSettings2.responsive = {};
    owlSettings2.responsive[parseInt(bsBreakpoint.xs)] = { items: 2 };
    owlSettings2.responsive[parseInt(bsBreakpoint.lg)] = { items: 4 };
    owlSettings2.responsive[parseInt(bsBreakpoint.xl)] = { items: 5 };
    owlSettings2.responsive[parseInt(bsBreakpoint.xxl)] = { items: 7, nav: true };
    var $owl2 = $('.owl-carousel-producenci').owlCarousel(owlSettings2);
    
    var owlSettings3 = {
      autoplay: true,
      loop: true,
      autoplayHoverPause: true,
      nav:  false,
      dots: false,
      items: 1,
      stagePadding: 0,
      margin: 0,
    };
    
    owlSettings3.responsive = {};
    owlSettings3.responsive[parseInt(bsBreakpoint.xs)] = { items: 0 };
    owlSettings3.responsive[parseInt(bsBreakpoint.lg)] = { items: 1 };
    owlSettings3.responsive[parseInt(bsBreakpoint.xl)] = { items: 2 };
    owlSettings3.responsive[parseInt(bsBreakpoint.xxl)] = { items: 3 };
    var $owl3 = $('.owl-carousel-header').owlCarousel(owlSettings3);
    
    
    /** Zdjecia karuzela + zoom + colorbox */
    $('.slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slider-nav'
    });
    $('.slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slider-for',
      arrows: true,
      centerMode: true,
      variableWidth: true,
      focusOnSelect: true,
      prevArrow: '<button type="button" class="slick-prev btn btn-kpsl-1 rounded-0 display-flex align-items-center"><i class="fa fa-angle-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next btn btn-kpsl-1 rounded-0 display-flex align-items-center"><i class="fa fa-angle-right"></i></button>'
      
    });
    $('.fotka').zoom({
      callback: function callback() {
        var newImageUrl = $(this).parent().attr('href');
        var currentImage = $(this).closest('.zoomImg').attr('src');

        if (newImageUrl !== currentImage) $(this).closest('.zoomImg').attr('src', newImageUrl);
        $(this).colorbox({ 
          href: this.src, 
          rel: 'zdjecia-produktu-zoom', 
          title: false, 
          maxWidth: '100%', 
          maxHeight: '100%' ,
          current: '<div style="position: relative; top:4px;">{current} / {total}</div>',
          next: '<i class="fa fa-angle-right"></i>',
          previous: '<i class="fa fa-angle-left"></i>',
          close: '<i class="fa fa-close"></i>'
        });
      }
    });
    $(".img-resize").colorbox({ rel: 'zdjecia-produktu', title: false, maxWidth: '100%', maxHeight: '100%' });
  });
})( jQuery );